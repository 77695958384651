<template lang="pug">
  article
    header
      .max-width
        span.logo workshop.blue
    .words
      .max-width
        p We are tired of generative NFTs.
    .projects.max-width    
      router-link.project(to="/crypto-slots")
        .project-placeholder
          img(src="../assets/images/edition-faces-animation.gif")
          //-div.video
            video(src="../assets/mov/crypto-slots.mp4", type="video/mp4", autoplay, loop, height="400",  width="400")
        .project-name
          h3 Crypto Slots
          //-img(src="../assets/images/emoji_slots.png")

      .project
        .project-placeholder.coming-soon
          div.circle
        .project-name
          h3 The Button


</template>

<script>

export default {
  name: 'Home',
  components: {
  }
}
</script>

<style lang="stylus" scoped>
  .words
    color #fff
    font-size 1.5rem
    text-align center
  article
    background rgb(0,80,157)
    //background linear-gradient(90deg, rgba(0,63,136,1) 0%, rgba(0,63,136,1) 50%, rgba(0,80,157,1) 50%, rgba(0,80,157,1) 100%)
    heigth 100vh
    min-height 100vh
  header
    background-color rgb(0,63,136)
    color #fff
    font-weight 500
    font-size 1em
    margin-bottom 3em
    padding 1em
    text-align center
    
    .logo
      font-size 2rem
  .projects
    display flex
    
    justify-content space-evenly
    .project
      display block
      padding .5em
      color #fff
      text-decoration none
      &:hover
        .project-placeholder
          border-color #ffd500
      .project-placeholder
        background-color #fff
        border 5px solid rgb(0,63,136)
        height 400px
        width 400px
        max-width 400px
        transition 300ms
        overflow hidden
        img
          vertical-align top
          max-width 100%
        &.coming-soon
          align-items center
          display flex
          justify-content center
          position relative
          &:after
            background rgba(0,0,0,.5)
            border-radius 1rem
            padding 2rem
            font-size 3rem
            font-weight 500
            content 'Coming Soon'
            text-align center
            position absolute
            transform rotate(-30deg)
          .circle
            background-color #AAA
            height 100px
            width 100px
            border-radius 100px
      .project-name
        text-align center
        h3
          font-size 1rem
      
        
      &:hover
        //border-color #ffd500
        border-color #fdc500

@media only screen and (max-width: 600px)
  .projects
    flex-direction column
    .project 
      .project-placeholder
        width 90%
        max-width 90%
        height auto
        max-height auto
        min-height 290px
.video
  //border-radius 5px
  overflow hidden
  height 400px
  width 400px
  video
    object-fit cover

</style>
